<template>
  <KepemilikanSahamForm mode="Ubah" module="Data Kepemilikan Saham"> </KepemilikanSahamForm>
</template>

<script>
import KepemilikanSahamForm from './form';

const KepemilikanSahamUpdate = {
  name: 'KepemilikanSahamUpdate',
  components: { KepemilikanSahamForm },
};

export default KepemilikanSahamUpdate;
</script>
